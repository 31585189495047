export const saving_fields = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'user.first_name',
        label: 'First Name',
        sortable: true,
    },
    {
        key: 'user.last_name',
        label: 'Last Name',
        sortable: true,
    },
    {
        key: 'user.phone_number',
        label: 'Phone Number',
        sortable: true,
    },
    {
        key: 'payment_amount',
        label: 'Amount',
        sortable: true
    },
    {
        key: 'txn_ref',
        label: 'Reference',
        sortable: true,
    },
    {
        key: 'user.member_status',
        label: 'Membership Status',
        sortable: true,
    },
    {
        key: 'created_at',
        label: 'Date Created',
        sortable: true,
    }
];

