<template>
  <div class="container-fluid">
    <div class="row">

      <div class="col-xl-4 xl-50">
        <px-card title="Electronic Goal">
          <div slot="with-padding">
            <div class="timeline-small">
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="shopping-bag"></feather></div>
                <div class="media-body">
                  <h6>Samsung TV <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 25,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="message-circle"></feather></div>
                <div class="media-body">
                  <h6>Fridge <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 30,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="minus-circle"></feather></div>
                <div class="media-body">
                  <h6>Microwave <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 15,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="shopping-bag"></feather></div>
                <div class="media-body">
                  <h6>Iphone <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 90,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary" @click="$bvModal.show('bv-modal-example')"><feather type="tag"></feather></div>
                <div class="media-body">
                  <h6>Lenovo Laptop <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 50,000</p></h6>

                </div>
              </div>
            </div>
          </div>
        </px-card>
      </div>

      <div class="col-xl-4 xl-50">
        <px-card title="Furniture Goals">
          <div slot="with-padding">
            <div class="timeline-small">
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="shopping-bag"></feather></div>
                <div class="media-body">
                  <h6>Sofa Set <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 70,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="message-circle"></feather></div>
                <div class="media-body">
                  <h6>Office Chair <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 10,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="minus-circle"></feather></div>
                <div class="media-body">
                  <h6>Dining Table <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 25,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="shopping-bag"></feather></div>
                <div class="media-body">
                  <h6>Bed 6x6 <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 20,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="tag"></feather></div>
                <div class="media-body">
                  <h6>Ceiling Board <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 85,000</p></h6>

                </div>
              </div>
            </div>
          </div>
        </px-card>
      </div>

      <div class="col-xl-4 xl-50">
        <px-card title="Rent Goals">
          <div slot="with-padding">
            <div class="timeline-small">
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="shopping-bag"></feather></div>
                <div class="media-body">
                  <h6>Single Room <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 5,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="message-circle"></feather></div>
                <div class="media-body">
                  <h6>Bed sitter <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 15,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="minus-circle"></feather></div>
                <div class="media-body">
                  <h6>1 Bedroom <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 18,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="shopping-bag"></feather></div>
                <div class="media-body">
                  <h6>2 Bedroom <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 20,000</p></h6>

                </div>
              </div>
              <div class="media">
                <div class="timeline-round m-r-30 timeline-line-1 bck-gradient-primary"><feather type="tag"></feather></div>
                <div class="media-body">
                  <h6>3 Bedroom <span class="pull-right f-14 offer" @click="$bvModal.show('bv-modal-example')">Send Offer</span><p class="f-14 amount">Ksh 30,000</p></h6>

                </div>
              </div>
            </div>
          </div>
        </px-card>
      </div>

      <b-modal id="bv-modal-example" centered hide-footer>
        <template #modal-title>
          <span>Customer Goals</span>
        </template>
        <form action="">
          <div class="row">
              <p>
                <b>Name</b><br>
                <input type="text" class="form-control" v-model="form.name" required>
              </p>
              <p>
                <b>Description</b><br>
                <textarea v-model="form.description" cols="3" rows="1" class="form-control" required></textarea>
              </p>
            </div>
              <p>
                <b>Amount Bid</b><br>
                <input type="text" v-model="form.amount" class="form-control" required>
              </p>
              <p>
                <b>Offers</b><br>
                <textarea v-model="form.offers" cols="5" rows="2" required class="form-control"></textarea>
              </p>

          <div class="form-group text-center">
            <button class="btn btn-pill btn-gradient-custom mt-3 pr-10 pl-10" style="padding-right: 10%;padding-left: 10%" @click="$bvModal.hide('bv-modal-example')">Send Bid </button>
          </div>

        </form>
      </b-modal>



    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      form:{
       name:'',
       description:'',
       amount:'',
       offers:''
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.amount{
  font-weight: normal;
  color: #999;
}
.offer{
  cursor: pointer;
  color: #158df7 !important;
}
</style>
