<template>
  <div>
    <div class="loader-box">
      <div class="loader-17"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner"
}
</script>

<style scoped>

</style>
