import axios from "axios";

class StatsService {
    getStats(){
        return axios.get('dashboard/data')
            .then(res => {
                return res.data;
           })
    }
}

export default new StatsService();
