import axios from 'axios';

class AgentCustomerService {

    getCustomer(user_id) {
        return  axios.get(`customer/show/${user_id}`)
            .then(res => {
                return res.data;
            })
    }
    getCustomerStats(user_id) {
        return  axios.post(`agent/customers/stats`,{user_id:user_id})
            .then(res => {
                return res.data;
            })
    }
    getCustomers(payload){
        let url = `agent/customers?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{user_id:localStorage.getItem('login_id')})
            .then((response) => {
                return response.data;
            })
    }
}


export default new AgentCustomerService();

