import members from "@/services/Member";


const state = {
    member:{},
    members:{},
    inactive_members:{},
    partial_members:{},
    referrals:{},
}

const mutations = {
    updateMembers(state,payload) {
        state.members = payload;
    },
    updateInactiveMembers(state,payload) {
        state.inactive_members = payload
        // state.no_of_subscribed_members = payload.subscribed_members;
    },
    updatePartialMembers(state,payload) {
        state.partial_members = payload;
    },
    updateReferrals(state,payload) {
        state.referrals = payload;
    }
}

const getters = {
    getMember(state) {
     return state.member;
    },
    getMembers(state) {
        return state.members;
    },
    getInactiveMembers(state) {
        return state.inactive_members;
    },
    getPartialMembers(state) {
        return state.partial_members;
    },
    getReferrals(state) {
        return state.referrals;
    }
}

const actions = {
    updateMembers({commit}, payload) {
        return members.getMembers(payload).then(response => {
                commit('updateMembers',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
    updateInactiveMembers({commit}, payload) {
        return members.getInactiveMembers(payload).then(response => {
                commit('updateInactiveMembers',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
    updatePartialMembers({commit}, payload) {
        return members.getPartialMembers(payload).then(response => {
                commit('updatePartialMembers',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
    updateReferrals({commit}, payload) {
        return members.getReferrals(payload).then(response => {
                commit('updateReferrals',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
}

export default {
    namespace: true,
    state,
    mutations,
    actions,
    getters
}
