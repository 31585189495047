import axios from "axios";

class PaymentService {
    availableAmount(payload){
        let url = `payment/available-amount?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{merchant_id:347})
            .then((response) => {
                return response.data;
            })
    }

    payout(payload){
        let url = `payment/pay-out?page=${payload.page}&page_size=${payload.size}&search=${payload.search}`;
        return axios.post(url,{merchant_id:347})
            .then((response) => {
                return response.data;
            })
    }

}

export default new PaymentService();

