<template>
  <div>
    <!-- Container-fluid starts-->
    <all-customers v-if="show_all"></all-customers>
    <create-customer v-if="show_create"></create-customer>
    <edit-customer v-if="show_edit" :customer="customer"></edit-customer>
    <inactivity-logout></inactivity-logout>

  </div>
</template>

<script>
import Edit from "@/pages/customers/Edit.vue";
import create from "@/pages/customers/create.vue";
import index from "@/pages/customers/Index.vue";
import Inactivity from "@/pages/auth/InactivityLogout.vue";

export default {
  components:{
    'edit-customer':Edit,
    'create-customer':create,
    'all-customers':index,
    'inactivity-logout':Inactivity,
  },
  data() {
    return {
      customer:{},
      show_all:true,
      show_create:false,
      show_edit:false,
    }
  },
  mounted() {
    this.listenEvents();

  },
  methods:{
    listenEvents() {
      bus.$on('hide_form',()=>{
        this.show_all = true;
        this.show_create = false;
        this.show_edit = false;
      })
      bus.$on('show_create_form',()=>{
        this.show_all = false;
        this.show_create = true;
        this.show_edit = false;
      })
      bus.$on('show_edit_form',(customer)=>{
        this.show_all = false;
        this.show_create = false;
        this.show_edit = true;
        this.customer = customer;
      })
    },
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.5) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
