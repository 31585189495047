import axios from "axios";

class MemberService {
    getMembers(payload){
        return axios.get(`active/members?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}`)
            .then(res => {
                console.log(res.data)
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }
    getInactiveMembers(payload){
        return axios.get(`inactive/members?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}\``)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }
    getPartialMembers(payload){
        return axios.get(`partials/members?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}\``)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }

    getReferrals(payload){
        return axios.get(`referral?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}\``)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }
}

export default new MemberService();
