export const member_fields = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'first_name',
        sortable: true
    },
    {
        key: 'last_name',
        sortable: true,
    },
    {
        key: 'phone_number',
        sortable: true,
    },
    {
        key: 'status',
        sortable: true,
    },
    {
        key: 'actions',
        label: 'Action',
        sortable: false
    },
];

