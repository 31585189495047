<template>
  <div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoutTimer: null,
      inactivityTimeout: 5 * 60 * 1000, // 5 minutes in milliseconds
    };
  },
  created() {
    this.startInactivityTimer();
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keypress', this.resetInactivityTimer);
  },
  methods: {
    startInactivityTimer() {
      this.logoutTimer = setTimeout(this.logout, this.inactivityTimeout);
    },
    resetInactivityTimer() {
      clearTimeout(this.logoutTimer);
      this.startInactivityTimer();
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.replace('/auth/login');
    },
  },
};
</script>
