<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Withdrawals Requests</h5>
                </div>
                <div class="col-md-4">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <VueDatePicker
                            v-model="date"
                            :min-date="minDate"
                            :max-date="maxDate"
                            placeholder="Select Date Range"
                            class="form-control"
                            range
                            fullscreen-mobile
                            validate
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <button class="btn btn-primary" @click="generateExcel()" :disabled="!date">Export Excel</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="requests.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                  <template #cell(actions)="row" v-if="is_admin">

                    <b-dropdown variant="transparent" class="m-md-2" no-caret>
                      <template #button-content>
                        <feather type="more-vertical"></feather>
                      </template>
                      <b-dropdown-item @click="approve(row.item, row.index, $event.target)">Approve</b-dropdown-item>
                      <b-dropdown-item @click="reject(row.item, row.index, $event.target)">Reject</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="requests.data && requests.data.length >= 1"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              @change="handlePageChange"
                              class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {VueDatePicker} from "@mathieustan/vue-datepicker";
import axios from "axios";
import {withdrawal_requests_field_defs} from "@/pages/withdrawals/fields/withdrawal_requests_field_defs";
export default {
  components: {VueDatePicker},
  data() {
    return {
      fields: withdrawal_requests_field_defs,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 50, 100],
      searchTitle: "",
      date: "",
      currentDate: new Date(),
      date_from:'',
      date_to:'',
      data:{}
    }
  },
  mounted() {
    this.retrieveRequests();
  },
  computed:{
    ...mapGetters({requests:'getRequests'}),

    minDate () {
      return new Date(
          this.currentDate.getFullYear() - 1,
          this.currentDate.getMonth(),
          this.currentDate.getDate()
      );
    },
    maxDate () {
      return new Date(
          this.currentDate.getFullYear() + 1,
          this.currentDate.getMonth(),
          this.currentDate.getDate(),
      );
  },
    is_admin(){
      var loginId=localStorage.getItem('login_id');
      return loginId === 1645 || loginId === 18 || loginId === 77;
    }
  },
  watch: {
    requests() {
      this.totalRows = this.requests.meta.total;
    },
    searchTitle() {
      this.retrieveRequests();
    },
    date() {
      this.date_from  = this.date.start;
      this.date_to  = this.date.end;
      this.retrieveRequests();
    }
  },
  methods:{
    retrieveRequests() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
      this.$store.dispatch('updateRequests',params)
    },
    handlePageChange(value) {
      this.currentPage = value
      this.retrieveLoans();
    },
    generateExcel() {
      const formData = {date_from:this.date.start,date_to:this.date.end}
      axios.post('withdrawal/requests-export',formData)
          .then((res) =>{

          })
    },
    approve(item, index, button) {
      axios.post('/withdrawal/send-approval',{id:item.id,status:'Approved','user_id':localStorage.getItem('login_id')})
          .then(res => {
            this.$router.go()
            console.log('message sent')

          }).catch(err => {
        console.log('error occurred in sending sms')
        console.log(err)
      })

    },

    reject(item, index, button) {
      axios.post('/withdrawal/send-approval',{id:item.id,status:'Rejected','user_id':localStorage.getItem('login_id')})
          .then(res => {
            this.$router.go();
            console.log('message sent')

          }).catch(err => {
        console.log('error occurred in sending sms')
        console.log(err)
      })

    },
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.5) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
