import Vue from "vue";
import Vuex from "vuex";
import 'es6-promise/auto'
import layout from './modules/layout'
import menu from './modules/menu'
import {auth} from './modules/auth'
import payment from './modules/payment'
import order from './modules/order'
import advance from './modules/advance'
import outletstaff from './modules/outlet_staff'
import roles from './modules/roles'
import outlets from './modules/outlets'
import createPersistedState from 'vuex-persistedstate'
import stats from "./modules/stats";
import members from "./modules/members";
import savings from "./modules/savings";
import agent_customers from "./modules/agent_customers";
import loans from "./modules/loans";
import withdrawals from "./modules/withdraw";

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
    modules: {
        layout,
        menu,
        auth,
        payment,
        order,
        advance,
        outletstaff,
        roles,
        outlets,
        stats,
        members,
        savings,
        agent_customers,
        loans,
        withdrawals
    },
  //plugins: [createPersistedState()],
    strict: false
});

