import axios from "axios";

class SavingService {
    getSavings(payload){
        console.log(payload)
       var url = `in/payments?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}`
        return axios.get(url)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }


    getSavingsSummary(payload){
        console.log(payload)
        var url = `member/savings/maturity?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}`
        return axios.get(url)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }
}



export default new SavingService();
