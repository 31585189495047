import stats from "@/services/Stats";

const state = {
    stats:[]
}

const getters = {
    getStats: (state) => {
        return state.stats;
    }
}

const mutations = {
    updateStats: (state,payload) =>{
        state.stats = payload;
    }
}

const actions = {
    updateStats({commit}) {
        return stats.getStats().then(
            response => {
                commit('updateStats',response.data);
               return Promise.resolve(response.data)
            },
            error => {
             return Promise.reject(error);
            }
        )
    }
}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

