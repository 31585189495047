<template>
    <!-- Page Header Start-->
      <div class="page-main-header" :class="{ open:!togglesidebar }">
        <div class="main-header-right row">
          <div class="main-header-left d-lg-none">
            <div class="logo-wrapper">
              <router-link to="/">
                <img src="../assets/images/logo_flexpay.png" alt="">
              </router-link>
              </div>
          </div>
          <div class="mobile-sidebar d-block" v-if="layout.settings.sidebar.type != 'horizontal_sidebar'">
            <div class="media-body text-right switch-sm">
              <label class="switch">
               <input id="sidebar-toggle" type="checkbox" checked="checked"><span class="switch-state" @click="toggle_sidebar"></span>
              </label>
            </div>
          </div>
          <div class="vertical-mobile-sidebar"><i class="fa fa-bars sidebar-bar"></i></div>
          <div class="nav-right col left-menu-header">
            <div class="d-xl-none mobile-toggle-left pull-right" @click="openmobilemenu1"><feather type="more-horizontal"></feather> <i data-feather="more-horizontal"></i></div>
          </div>
          <div class="nav-right col pull-right right-menu row">
            <ul class="nav-menus" :class="{ open: mobiletoggle2}">

              <li>
                <router-link to="/"><feather type="home" ></feather></router-link>
              </li>
              <li>
                <div class="dropdown">
                 <button class="btn dropdown-toggle" type="button"  @click="logout"><feather type="power" ></feather></button>
                </div>
              </li>
            </ul>
          </div>
          <div class="d-lg-none mobile-toggle pull-right" @click="openmobilemenu2"><feather type="more-horizontal"></feather> <i data-feather="more-horizontal"></i></div>
        </div>
      </div>
      <!-- Page Header Ends -->
</template>
<script>
var body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";
export default {
  name: 'Search',
  data() {
    return {
      terms: '',
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      toggle_sidebar_var:false,
      clicked:false,
      toggle_rightsidebar_var:false,
      rightclicked:false,
      mobile_toggle:false,
      mobile_search: false,
      mobiletoggle1: false,
      mobiletoggle2: false
    }
  },
  computed: {
    ...mapState({
      menuItems: state => state.menu.searchData,
      layout: state => state.layout.layout,
      togglesidebar: (state) => state.menu.togglesidebar
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.replace('/auth/login');
    },
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
    openmobilemenu1() {
      this.mobiletoggle1 = !this.mobiletoggle1
    },
    openmobilemenu2() {
      this.mobiletoggle2 = !this.mobiletoggle2
    },
    search_open() {
      this.searchOpen = true
    },
    search_close() {
      this.searchOpen = false
    },
    searchTerm: function() {
      this.$store.dispatch('menu/searchTerm', this.terms)
    },
    addFix() {
      body.classList.add("offcanvas")
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove("offcanvas")
      this.searchResult = false;
      this.terms = ''
    },
    // toggle_sidebar(){
    //   this.toggle_sidebar_var = !this.toggle_sidebar_var,
    //   this.clicked = !this.toggle_sidebar_var,
    //   this.$emit('clicked',this.clicked)
    // },
    toggle_rightsidebar(){
      this.toggle_rightsidebar_var = !this.toggle_rightsidebar_var,
      this.rightclicked = !this.toggle_rightsidebar_var,
      this.$emit('rightclicked',this.rightclicked)
    },
    toggle_fullscreen() {
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  },
  watch: {
    menuItems: function () {
      this.terms ? this.addFix() : this.removeFix();
      if(!this.menuItems.length)
        this.searchResultEmpty = true;
      else
        this.searchResultEmpty = false;
    }
  }
}
</script>
