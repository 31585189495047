<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">

<div class="row">
  <div class="col-md-4">
    <div class="card card-stats mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Savings (KES)</h6>
            <span class="h4 font-weight-bold">{{stats.savings}}</span>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i> 3.48%</span>
          <span class="text-nowrap">Compared to last month</span>
        </p>
        <hr>
        <div class="row">
          <div class="col">
            <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of Members</h6>
            <span class="h4 font-weight-bold">{{stats.members_with_savings}}</span>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
          <span class="text-nowrap">Compared to last month</span>
        </p>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card card-stats mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Loans(KES)</h6>
            <span class="h4 font-weight-bold">{{stats.loans}}</span>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
          <span class="text-nowrap">Compared to last month</span>
        </p>
        <hr>
        <div class="row">
          <div class="col">
            <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of Members </h6>
            <span class="h4 font-weight-bold">{{stats.no_loan}}</span>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
          <span class="text-nowrap">Compared to last month</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card card-stats mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Membership Fee</h6>
            <span class="h4 font-weight-bold">{{stats.membership_fee}}</span>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i> 3.48%</span>
          <span class="text-nowrap">Compared to last month</span>
        </p>
        <hr>
        <div class="row">
          <div class="col">
            <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of Subscribed Members</h6>
            <span class="h4 font-weight-bold">{{stats.active_members}}</span>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
          <span class="text-nowrap">Compared to last month</span>
        </p>
      </div>
    </div>
  </div>
</div>

   <div class="row mt-4">
        <div class="col-md-4">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Average Savings</h6>
                  <span class="h4 font-weight-bold">{{stats.average_savings}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of Members</h6>
                  <span class="h4 font-weight-bold">{{stats.members_with_savings}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Overdue Loan (KES)</h6>
                  <span class="h4 font-weight-bold">{{stats.repayment}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of Overdue Loans</h6>
                  <span class="h4 font-weight-bold">{{stats.no_repayment}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Saving Interest</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of Savings</h6>
                  <span class="h4 font-weight-bold">{{stats.no_savings}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-3">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Average Loan(KES)</h6>
                  <span class="h4 font-weight-bold">{{stats.due_payment}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number Members</h6>
                  <span class="h4 font-weight-bold">{{stats.no_due_payment}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Recovered Amount (KES)</h6>
                  <span class="h4 font-weight-bold">{{stats.repayment}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of Recovered Loans</h6>
                  <span class="h4 font-weight-bold">{{stats.no_repayment}}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Flexsako Interest(KES)</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of Loans</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>
          <div class="col-md-3">
              <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                      <div class="row">
                          <div class="col">
                              <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Referrals Converted</h6>
                              <span class="h4 font-weight-bold">{{stats.referral_converted}}</span>
                          </div>
                      </div>
                      <p class="mt-3 mb-0 text-muted text-sm">
                          <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                          <span class="text-nowrap">Compared to last month</span>
                      </p>
                      <hr>
                      <div class="row">
                          <div class="col">
                              <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Refereed Number</h6>
                              <span class="h4 font-weight-bold">{{stats.referral_total}}</span>
                          </div>
                      </div>
                      <p class="mt-3 mb-0 text-muted text-sm">
                          <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                          <span class="text-nowrap">Compared to last month</span>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- Container-fluid Ends -->
  </div>
</template>
<script>
import * as Chartist from 'chartist';
import TotalSales from "@/pages/charts/chartjs/TotalSales";
import {mapGetters} from "vuex";
export default {
  components:{
    'Linechart':TotalSales
  },
  data() {
    return {
    };
  },
  methods: {
    openCardOption() {
      this.cardOption = !this.cardOption;
    }
  },

  created() {
    this.$store.dispatch('updateStats')
  },
  watch:{
    stats() {
      console.log(this.stats)
    }
  },
  computed:{
    ...mapGetters({stats:'getStats'})
  }
};
</script>

<style scoped>
.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.1) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
