import withdrawals from "@/services/Withdrawal";


const state = {
    requests:{},
    approvals:{},
    matured:{},
}

const mutations = {
    updateRequests(state,payload) {
        state.requests = payload;
    },
    updateApprovals(state,payload) {
        state.approvals = payload;
    },
    updateMatured(state,payload) {
        state.matured = payload;
    }
}

const getters = {
    getRequests(state) {
        return state.requests;
    },
    getApprovals(state) {
        return state.approvals;
    },
    getMatured(state) {
        return state.matured;
    }
}

const actions = {
    updateRequests({commit}, payload) {
        return withdrawals.getWithdrawRequests(payload).then(response => {
                commit('updateRequests',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
    updateApprovals({commit}, payload) {
        return withdrawals.getWithdrawApproved(payload).then(response => {
                commit('updateApprovals',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
    updateMatured({commit}, payload) {
        return withdrawals.getWithdrawMatured(payload).then(response => {
                commit('updateMatured',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
}

export default {
    namespace: true,
    state,
    mutations,
    actions,
    getters
}
