<template>
<div>
  <div class="fxt-content">
    <div class="fxt-header">
      <a href="/" class="fxt-logo"><img src="../../assets/images/logo.png" alt="Logo" width="45%"></a>
      <div class="fxt-style-line">
        <h2>Login To Proceed</h2>
      </div>
    </div>
    <div v-if="message" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
    <p class="text-danger">
     {{ message }}
    </p>
  </div>
    <div class="fxt-form">
      <form method="POST" @submit.prevent="handleLogin">
        <div class="form-group">
          <div class="fxt-transformY-50 fxt-transition-delay-1">
            <input type="email" class="form-control" v-model="user.email" placeholder="Email Address" required>
          </div>
        </div>
        <div class="form-group">
          <div class="fxt-transformY-50 fxt-transition-delay-2">
            <input type="password" class="form-control" v-model="user.password" placeholder="Password" required>
          </div>
        </div>
        <div class="form-group">
          <div class="fxt-transformY-50 fxt-transition-delay-3">
            <div class="fxt-checkbox-area">
              <div class="checkbox">
                <input id="checkbox1" type="checkbox">
                <label for="checkbox1">Keep me logged in</label>
              </div>
              <b-button id="default-light" variant="light" @click="showAgentLogin()">Promoter Login?</b-button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="fxt-transformY-50 fxt-transition-delay-4">
            <button type="submit" class="fxt-btn-fill" v-if="!loading">Log in</button>
            <app-spinner v-if="loading"></app-spinner>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import User from '../../models/user';
import VerifyOtp from "@/pages/auth/VerifyOtp.vue";
export default {
  components:{
    VerifyOtp
  },
  data() {
    return {
      loading: false,
      message: '',
      show_otp:false,
      user:{
        email: "",
        password: ""
      },
    };
  },
  mounted() {
   if (this.loggedIn)
     this.$router.replace({path:'/'})
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
    handleLogin(){
        this.loading = true;
        if (this.user.email && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
              () => {
               this.show_otp = true;
               this.loading = false;
               this.hideForm();

              },
              error => {
                this.message = error.response.data.error;
                this.loading = false;
                this.$toasted.show("Invalid Login Credentials. Please try again.", {theme: 'outline',position: "top-center", type: 'error', duration: 2000});
              }
          );
        }
    },
    hideForm() {
      bus.$emit('hide_login');
    },
    showAgentLogin() {
      bus.$emit('show_login_agent');
    }

  },
};
</script>

<style scoped>


</style>
