<template>
  <div>
    <div class="fxt-content">
      <div class="fxt-header">
        <a href="/" class="fxt-logo"><img src="../../assets/images/logo.png" alt="Logo" width="45%"></a>
        <div class="fxt-style-line">
          <h2>Login To Proceed</h2>
        </div>
      </div>
      <div v-if="message" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
        <p class="text-danger">
          {{ message }}
        </p>
      </div>
      <div class="fxt-form">
        <form method="POST" @submit.prevent="handleLogin">
          <div class="form-group">
            <div class="fxt-transformY-50 fxt-transition-delay-1">
              <input type="text" class="form-control" v-model="user.phone_number" placeholder="Phone Number" required autocomplete="off">
            </div>
          </div>
          <div class="form-group">
            <div class="fxt-transformY-50 fxt-transition-delay-3">
              <div class="fxt-checkbox-area">
                <div class="checkbox">
                  <input id="checkbox1" type="checkbox">
                  <label for="checkbox1">Keep me logged in</label>
                </div>
                <router-link to="login" class="switcher-text">Admin Login?</router-link>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="fxt-transformY-50 fxt-transition-delay-4">
              <button type="submit" class="fxt-btn-fill" v-if="!loading">Log in</button>
              <app-spinner v-if="loading"></app-spinner>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import login from "@/pages/auth/login.vue";
import VerifyOtp from "@/pages/auth/VerifyOtp.vue";
export default {
  data() {
    return {
      loading: false,
      message: '',
      show_otp:false,
      user:{
        phone_number: "",
      },
    }
  },
  mounted() {
    if (this.loggedIn)
      this.$router.replace({path:'/agent'})
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
    handleLogin(){
      this.loading = true;
      if (this.user.phone_number) {
        this.$store.dispatch('auth/loginPhone', this.user).then(
            () => {
              this.show_otp = true;
              this.loading = false;
              this.hideForm();

            },
            error => {
              this.message = error.response.data.error;
              this.loading = false;
              this.$toasted.show("Invalid Login Credentials. Please try again.", {theme: 'outline',position: "top-center", type: 'error', duration: 2000});
            }
        );
      }
    },
    hideForm() {
      bus.$emit('hide_login');
    }

  },
}
</script>

<style scoped>
@import '../../assets/css/bootstrap.min.css';
@import '../../assets/css/style.css';
@import '../../assets/css/fontawesome-all.min.css';
.bg_image {
  background-image: url('~@/assets/images/bg.jpg.webp');
}
</style>
