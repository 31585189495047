<template>
<div>

  <section class="fxt-template-animation fxt-template-layout17 bg_image">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-6 col-lg-7 col-sm-12 col-12 fxt-bg-color">
          <login v-if="show_login"></login>
          <promoterLogin v-if="show_login_agent"></promoterLogin>
          <verify-otp v-if="show_otp"></verify-otp>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import login from "@/pages/auth/login.vue";
import VerifyOtp from "@/pages/auth/VerifyOtp.vue";
import promoterLogin from "@/pages/auth/promoterLogin.vue";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      show_login:true,
      show_otp:false,
      show_login_agent:false,
    }
  },
  computed:{
    ...mapGetters({'scope_type':'auth/getScope'})
  },
  components:{
    login,
   promoterLogin,
    VerifyOtp
  },

  mounted() {
    bus.$on('show_login_agent',() =>{
      this.show_login =false;
      this.show_login_agent =true;
      this.show_otp = false;
    })
    bus.$on('hide_login',() =>{
      this.show_login =false;
      this.show_login_agent =false;
      this.show_otp = true;
    })
    bus.$on('hide_otp',() =>{
       if (this.scope_type ===true) {
         this.show_login_agent = true;
       }else {
         this.show_login = true;
       }
      this.show_otp = false;
    })
  }
}
</script>

<style scoped>
@import '../../assets/css/bootstrap.min.css';
@import '../../assets/css/style.css';
@import '../../assets/css/fontawesome-all.min.css';
.bg_image {
  background-image: url('~@/assets/images/bg.jpg.webp');
}
</style>
