import Vue from 'vue'
import Router from "vue-router";

import Body from '../components/body'
import BodyPromo from '../components/body_promo.vue'

/* sample page */
import Dashboard from '../pages/dashboard/index'
import DashboardPromoter from '../pages/dashboard/promoter_index.vue'
import Payment from '../pages/payments/index.vue'
import Payout from '../pages/payments/pay_out'
import ValidatedOrders from '../pages/orders/validated_orders'
import UnvalidatedOrders from '../pages/orders/unvalidated_orders'
import Refunds from '../pages/orders/refunds'
import Advance from '../pages/advances/index'
import History from '../pages/advances/history'
import NewAdvance from '../pages/advances/add'
import Users from '../pages/users/index'
import AddUser from '../pages/users/add'
import Roles from '../pages/users/roles/index'
import SamplePage from '../pages/sample_page'
import Auth from '../pages/auth/auth'
import login from '../pages/auth/AppLogin.vue'
import forgotPassword from '../pages/auth/forgotPassword'
import register from  '../pages/auth/Register'
import resetPassword from  '../pages/auth/confirmPassword'
import CustomerGoal from "@/pages/goals/CustomerGoal";
import Member from "@/pages/members/index.vue";
import InactiveMember from "@/pages/members/inactive/index.vue";
import PartialMember from "@/pages/members/partial/index.vue";
import Saving from "@/pages/savings/index.vue";
import SavingMaturity from "@/pages/savings/Summary.vue";
import Loans from "@/pages/loans/index.vue";
import LoanSettled from "@/pages/loans/Settled.vue";
import LoanRequest from "@/pages/loans/Request.vue";
import LoanActive from "@/pages/loans/Active.vue";
import LoanOverdue from "@/pages/loans/Overdue.vue";
import LoanRepayments from "@/pages/loans/Repayment.vue";
import WithdrawRequest from "@/pages/withdrawals/index.vue";
import WithdrawApproved from "@/pages/withdrawals/Approved.vue";
import MaturedSavings from "@/pages/withdrawals/Matured.vue";
import Referral from "@/pages/referrals/index.vue";
import View from "@/pages/members/View.vue";

// component

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'index' }},

  {
    path: '/',
    component: Body,
    children: [
    {
      path: '',
      name: 'index',
      component: Dashboard,
      meta: {
        title: 'Default Dashboard | Endless - Premium Admin Template',
      }
    },
    {
      path: 'ecommerce',
      name: 'ecommerce',
      component: SamplePage,
      meta: {
        title: 'Ecommerce Dashboard | Flexpay - Dashboard',
      }
    }
    ]
  },

  {
    path: '/agent',
    component: BodyPromo,
    children: [
      {
        path: '',
        name: 'agent',
        component: DashboardPromoter,
        meta: {
          title: 'Agent Dashboard | Flexpay - Dashboard',
        }
      },
    ]
  },

  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: login,
        meta: {
          title: 'Login | Flexpay - Dashboard',
        }
      },
      {
        path: 'register',
        name: 'Register',
        component: register,
        meta: {
          title: 'Register | Flexpay - Dashboard',
        }
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: forgotPassword,
        meta: {
          title: 'Forgot Password | Flexpay - Dashboard',
        }
      },
      {
        path: 'reset-password',
        name: 'Reset Password',
        component: resetPassword,
        meta: {
          title: 'Reset Password | Flexpay - Dashboard',
        }
      }
    ]
  },

  {
    path: '/payments',
    component: Body,
    children: [
      {
        path: '',
        name: 'payments',
        component: Payment,
        meta: {
          title: 'Payments | Flexpay - Dashboard',
        }
      },
      {
        path: 'pay-out',
        name: 'pay-out',
        component: Payout,
        meta: {
          title: 'Payouts | Flexpay - Dashboard',
        }
      },
    ]
  },
  {
    path: '/members',
    component: Body,
    children: [
      {
        path: 'active',
        name: 'active',
        component: Member,
        // meta: {
        //   title: 'Active Members | FlexSako',
        // },
        children: [
          {path: 'view',name:'view-member', component:View}
        ]
      },
      {
        path: 'inactive',
        name: 'inactive',
        component: InactiveMember,
        meta: {
          title: 'Inactive Members | FlexSako',
        }
      },
      {
        path: 'partial',
        name: 'partial',
        component: PartialMember,
        meta: {
          title: 'Partial Members | FlexSako',
        }
      }
    ]
  },

  {
    path: '/savings',
    component: Body,
    children: [
      {
        path: '',
        name: 'savings',
        component: Saving,
        meta: {
          title: 'Savings | FlexSako',
        }
      }
    ]
  },
  {
    path: '/savings-maturity',
    component: Body,
    children: [
      {
        path: '',
        name: 'savings-maturity',
        component: SavingMaturity,
        meta: {
          title: 'Savings Maturity | FlexSako',
        }
      }
    ]
  },


  {
    path: '/loans',
    component: Body,
    children: [
      {
        path: '',
        name: 'loans',
        component: Loans,
        meta: {
          title: 'Loans | FlexSako',
        }
      }
    ]
  },
  {
    path: '/loan-repayments',
    component: Body,
    children: [
      {
        path: '',
        name: 'loan-repayments',
        component: LoanRepayments,
        meta: {
          title: 'Loan Repayments | FlexSako',
        }
      }
    ]
  },

  {
    path: '/loan-overdue',
    component: Body,
    children: [
      {
        path: '',
        name: 'loan-overdue',
        component: LoanOverdue,
        meta: {
          title: 'Loan Overdue | FlexSako',
        }
      }
    ]
  },

  {
    path: '/loan-active',
    component: Body,
    children: [
      {
        path: '',
        name: 'loan-active',
        component: LoanActive,
        meta: {
          title: 'Loan Active | FlexSako',
        }
      }
    ]
  },

  {
    path: '/loan-settled',
    component: Body,
    children: [
      {
        path: '',
        name: 'loan-settled',
        component: LoanSettled,
        meta: {
          title: 'Loan Settled | FlexSako',
        }
      }
    ]
  },
  {
    path: '/loan-request',
    component: Body,
    children: [
      {
        path: '',
        name: 'loan-request',
        component: LoanRequest,
        meta: {
          title: 'Loan Settled | FlexSako',
        }
      }
    ]
  },

  {
    path: '/withdrawals',
    component: Body,
    children: [
      {
        path: '',
        name: 'withdrawals',
        component: WithdrawRequest,
        meta: {
          title: 'Withdrawals | FlexSako',
        }
      }
    ]
  },
  {
    path: '/approved',
    component: Body,
    children: [
      {
        path: '',
        name: 'approved',
        component: WithdrawApproved,
        meta: {
          title: 'Approved Withdrawals | FlexSako',
        }
      }
    ]
  },
  {
    path: '/matured',
    component: Body,
    children: [
      {
        path: '',
        name: 'matured',
        component: MaturedSavings,
        meta: {
          title: 'Matured Withdrawals | FlexSako',
        }
      }
    ]
  },

  {
    path: '/referral',
    component: Body,
    children: [
      {
        path: '',
        name: 'referral',
        component: Referral,
        meta: {
          title: 'Referrals | FlexSako',
        }
      }
    ]
  },

  {
    path: '/orders',
    component: Body,
    children: [
      {
        path: 'validated',
        name: 'validated',
        component: ValidatedOrders,
        meta: {
          title: 'validated Orders | Flexpay - Dashboard',
        }
      },
      {
        path: 'unvalidated',
        name: 'unvalidated',
        component: UnvalidatedOrders,
        meta: {
          title: 'Unvalidated Orders | Flexpay - Dashboard',
        }
      },
      {
        path: 'refund',
        name: 'refund',
        component: Refunds,
        meta: {
          title: 'Refunds | Flexpay - Dashboard',
        }
      },
    ]
  },
  {
    path: '/advance',
    component: Body,
    children: [
      {
        path: 'all',
        name: 'all',
        component: Advance,
        meta: {
          title: 'Advances | Merchant - Dashboard',
        }
      },
      {
        path: 'history',
        name: 'history',
        component: History,
        meta: {
          title: 'Advances History | Merchant - Dashboard',
        }
      },
      {
        path: 'request',
        name: 'request',
        component: NewAdvance,
        meta: {
          title: 'Request Advance | Merchant - Dashboard',
        }
      },
    ]
  },
  {
    path: '/goals',
    component: Body,
    children: [
      {
        path: '',
        name: 'customer',
        component: CustomerGoal,
        meta: {
          title: 'Customer Goal | Merchant - Dashboard',
        }
      }
    ]
  },
  {
    path: '/users',
    component: Body,
    children: [
      {
        path: 'all',
        name: 'all-users',
        component: Users,
        meta: {
          title: 'Users | Merchant - Dashboard',
        }
      },
      {
        path: 'new',
        name: 'new',
        component: AddUser,
        meta: {
          title: 'New User | Merchant - Dashboard',
        }
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
        meta: {
          title: 'User Roles | Merchant - Dashboard',
        }
      },
    ]
  },
  ];

console.log('ppppp')
const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  console.log('=+++++++====')
  const publicPages = ['/auth/login', '/auth/register','/auth/forgot-password','/auth/reset-password','/auth/agent-login'];
  const agentPages = ['/agent'];
  var authRequired = !publicPages.includes(to.path);
  var agentRoute = agentPages.includes(to.path);

  const loggedIn = localStorage.getItem('flex_token');
  const isAgent = localStorage.getItem('is_agent');
     console.log(loggedIn)

  if (authRequired && !loggedIn) {
    console.log('iii ' + authRequired);
    return next({
      path: '/auth/login'
    });
  }

  if (isAgent === 'true' && !agentRoute) {
    console.log('kkkk ' + isAgent);
    return next({
      path: '/agent'
    });
  }

  next();
});

export default router
