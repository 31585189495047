<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Unvalidated Orders</h5>
                </div>
                <div class="col-md-6">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-4">
                  <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                  >
                    <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="unvalidated.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                  <template #cell(actions)="row">
                    <b-dropdown variant="transparent" class="m-md-2" no-caret>
                      <template #button-content>
                        <feather type="more-vertical"></feather>
                      </template>
                      <b-dropdown-item @click="validateOrder(row.item, row.index, $event.target)">Validate</b-dropdown-item>
                      <b-dropdown-item @click="refundOrder(row.item, row.index, $event.target)">Refund</b-dropdown-item>
                    </b-dropdown>
                  </template>
                <div>
                </div>
              </b-table>
              </div>
              <b-modal :id="refundModal.id"  :title="'Enter Reason'" hide-footer @hide="resetRefundModal" centered>
                <form @submit.prevent="handleRefund">

                  <div class="form-group">
                    <input type="text" class="form-control" v-model="form1.slip_no" required>
                  </div>

                  <div class="form-group text-center">
                    <button class="btn btn-pill btn-gradient-custom" type="submit">Refund</button>
                  </div>
                </form>
              </b-modal>

              <b-modal :id="validateModal.id" :title="'Enter the Authentication code provided by the customer'" hide-footer @hide="resetValidateModal" centered>
                <form @submit.prevent="handleValidate">

                  <div class="form-group">
                    <input type="text" class="form-control" v-model="form1.slip_no" required>
                  </div>

                  <div class="form-group text-center">
                    <button class="btn btn-pill btn-gradient-custom" type="submit">Validate</button>
                  </div>
                </form>
              </b-modal>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="unvalidated.data && unvalidated.data.length >= 1"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              @change="handlePageChange"
                              class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {unvalidated_field_defs} from "@/pages/orders/fields/unvalidated_field_defs";
export default {
  data() {
    return {
      fields: unvalidated_field_defs,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      searchTitle: "",
      form1:{
        slip_no:'',
      },
      refundModal: {
        id: 'refund-modal',
        title: '',
        content: ''
      },
      validateModal: {
        id: 'validate-modal',
        title: '',
        content: ''
      },
    }
  },

  mounted() {
    this.retrieveUnvalidated();
  },
  computed:{
    ...mapGetters({unvalidated:'getUnvalidated'})
  },
  watch: {
    unvalidated() {
        console.log('8***')
        console.log(this.totalRows)
      this.totalRows = this.unvalidated.total;
    },
    searchTitle() {
      this.retrieveUnvalidated();
    }
  },
  methods:{
    retrieveUnvalidated() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
      this.$store.dispatch('updateUnvalidated',params)
    },
    handlePageChange(value) {
      this.currentPage = value
      this.retrieveUnvalidated();
    },
    refundOrder(item, index, button) {
      this.refundModal.title = `Row index: ${index}`
       this.refundModal.content = 'init refund'
       this.$root.$emit('bv::show::modal', this.refundModal.id, button)
    },
    resetRefundModal() {
      this.refundModal.title = ''
      this.refundModal.content = ''
    },
    validateOrder(item, index, button) {
      this.form1.booking_reference = item.booking_reference
      console.log(item)
      console.log(index)
      console.log(button)
      this.validateModal.title = `Row index: ${index}`
      this.validateModal.content = 'init validate'
      this.$root.$emit('bv::show::modal', this.validateModal.id, '.dropdown-item')

    },
    resetValidateModal() {
      this.refundModal.title = ''
      this.refundModal.content = ''
    },
    handleValidate() {
      this.$root.$emit('bv::hide::modal', this.validateModal.id, '.dropdown-item');
      this.success('Record has been validated');
      this.form1.slip_no='';
    },
    handleRefund() {
      this.$root.$emit('bv::hide::modal', this.refundModal.id, '.dropdown-item');
      this.success('Record has been refunded');
      this.form1.slip_no = '';
    },
    handleOrder() {
      console.log('submit validation...')
    },
    success(message)
    {
      this.$toasted.show(message, {theme: 'outline',position: "top-right", fullWidth:true, type: 'success', duration: 2000});
    },
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

</style>
