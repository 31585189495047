<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Savings(KES)</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i> 3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of active Savings</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">On Track Savings(KES)</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
                <div class="col-auto">
                  <div class="">
                    <i class="fa fa-users fa-3x"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i> 3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Number of on Track</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Estimated Savings(KES)</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i> 3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
              <hr>
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Estimated Number</h6>
                  <span class="h4 font-weight-bold">0</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i>  3.48%</span>
                <span class="text-nowrap">Compared to last month</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Total Savings</h5>
                </div>
                <div class="col-md-4">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                  >
                    <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>

              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="savings.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="savings.data && savings.data.length >= 1"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              @change="handlePageChange"
                              class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {saving_fields} from "@/pages/savings/fields/saving_field_defs";
export default {
  data() {
    return {
      fields: saving_fields,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 50, 100],
      searchTitle: ""
    }
  },
  mounted() {
    this.retrieveSavings();
  },
  computed:{
    ...mapGetters({savings:'getSavings'})
  },
  watch: {
    savings() {
      this.totalRows = this.savings.meta.total;
      console.log('rows '+this.totalRows)
      console.log(this.savings.data.length)
    },
    searchTitle() {
      this.retrieveSavings();
    }
  },
  methods:{
    retrieveSavings() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
      this.$store.dispatch('updateSavings',params)
    },
    handlePageChange(value) {
      console.log('curr '+ value)
      this.currentPage = value
      this.retrieveSavings();
    }
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.5) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
