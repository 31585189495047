<template>
  <div>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8 offset-sm-2">

          <px-card title="Update Customer">

            <div slot="with-padding">
              <form @submit.prevent="submitDetails()">
                <div class="col-xs-12">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">First Name</label>
                      <input class="form-control" type="text" v-model="form.first_name" required="required">
                    </div>
                    <div class="form-group">
                      <label class="control-label">Last Name</label>
                      <input class="form-control" type="text" v-model="form.last_name" required="required">
                    </div>
                    <div class="form-group">
                      <label class="control-label">Phone Number</label>
                      <input class="form-control" type="text" v-model="form.phone_number" required="required">
                    </div>

                    <div class="form-group">
                      <label class="control-label">Email</label>
                      <input class="form-control" type="email" placeholder="name@gmail.com" v-model="form.customer_email">
                    </div>

                    <div class="form-group">
                      <label class="control-label">ID Number</label>
                      <input class="form-control" type="text" v-model="form.national_id" required="required">
                    </div>

                    <div class="form-group">
                      <button class="btn btn-warning" type="button" @click="goBack()">Cancel</button>
                      <button class="btn btn-primary ml-3" type="submit" :disabled="loading">Update</button>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>
<script>
import axios from 'axios';
export default {
  props:{
    customer:{}
  },
  data(){
    return {
      loading:false,
      form:{
        first_name:'',
        last_name:'',
        national_id:'',
        phone_number:'',
        customer_email:'',
      }
    }
  },
  mounted() {
    this.form = this.customer
    this.form.customer_email = this.customer.email;
  },
  methods:{
    goBack() {
      bus.$emit('hide_form');
    },
    submitDetails() {
      if (!this.checkDetails()){
        return this.danger_alert_state('All Fields are required!');
      }

      this.loading =true;
      this.form.agent_id = localStorage.getItem('login_id');
      axios.put(`membership/account/update/${this.customer.user_id}`,this.form)
          .then(res => {
            this.success_alert_state();
            this.loading=false;
            bus.$emit('hide_form')
          }).catch(err => {
            this.loading = false;
        let error = err.response.data.error_body;
        if (err.response.data.status ===422) {
          error = Object.values(error)[0][0];
        }
        return this.danger_alert_state(error);
      })

      console.log(this.form)
    },
    checkDetails() {
      return !(this.form.first_name === '' || this.form.last_name === '' ||
          this.form.phone_number === '' || this.form.national_id === '');

    },

    success_alert_state:function(){
      this.$swal({
        title:"Good job!",
        text:"Customer Details Updated Successfully.",
        type:'success'
      });
    },
    danger_alert_state:function(message){
      this.$swal({
        title:"Error Occurred!",
        text:message,
        type:'error'
      });
    }
  }

}
</script>