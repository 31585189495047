import axios from "axios";

class loanService {
    getLoans(payload){
        console.log(payload)
       var url = `all-loans?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&status=${payload.status}&date_from=${payload.date_from}&date_to=${payload.date_to}`
        return axios.get(url)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }

    getLoanRepayments(payload){
        console.log(payload)
        var url = `loan-repayments?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}`
        return axios.get(url)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }
}



export default new loanService();
