export const inactive_member_fields = [
    {
        key: 'sako_reference',
        label: '#',
        sortable: true
    },
    {
        key: 'first_name',
        sortable: true
    },
    {
        key: 'last_name',
        sortable: true,
    },
    {
        key: 'phone_number',
        sortable: true,
    },
    {
        key: 'national_id',
        sortable: true,
    },
    {
        key: 'email',
        sortable: true,
    },
    {
        key: 'member_status',
        sortable: true,
    },
    {
        key: 'created_at',
        label: 'Date Created',
        sortable: true,
    },
];

