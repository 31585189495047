import agent from "@/services/AgentCustomers";

const state = {
    customers:{},
    customer:{},
    customer_stats:{},
}

const getters = {
    getCustomers(state) {
       return state.customers;
    },
    getCustomer(state) {
        return state.customer;
    },
    getCustomerStats(state) {
        return state.customer_stats;
    }
}

const mutations = {
    updateCustomers(state,payload) {
        state.customers = payload;
    },
    updateCustomer(state,payload) {
        state.customer = payload;
    },
    updateCustomerStats(state,payload) {
        state.customer_stats = payload;
    }
}

const actions = {
    updateCustomers({commit},payload) {
        return agent.getCustomers(payload).then(response => {
            commit('updateCustomers',response);
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },

    updateCustomer({commit},payload) {
        return agent.getCustomer(payload).then(response => {
                commit('updateCustomer',response);
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    updateCustomerStats({commit},payload) {
        return agent.getCustomerStats(payload).then(response => {
                commit('updateCustomerStats',response);
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },

}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}
