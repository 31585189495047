import loans from "@/services/Loans";


const state = {
    loans:{},
    loan_repayments:{},
}

const mutations = {
    updateLoans(state,payload) {
        state.loans = payload;
    },
    updateLoanRepayments(state,payload) {
        state.loan_repayments = payload;
    }
}

const getters = {
    getLoans(state) {
        return state.loans;
    },
    getLoanRepayments(state) {
        return state.loan_repayments;
    }
}

const actions = {
    updateLoans({commit}, payload) {
        return loans.getLoans(payload).then(response => {
          //  alert(JSON.stringify(response))
                commit('updateLoans',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
    updateLoanRepayments({commit}, payload) {
        return loans.getLoanRepayments(payload).then(response => {
                commit('updateLoanRepayments',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    }
}

export default {
    namespace: true,
    state,
    mutations,
    actions,
    getters
}
