<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
          <h5>Show member details</h5>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {member_fields} from "@/pages/members/fields/member_field_defs";
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import axios from "axios";

export default {
    components:{
      VueDatePicker
    },
  data() {
    return {
      fields: member_fields,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 150, 200],
      searchTitle: "",
      date: "",
      currentDate: new Date(),
      date_from:'',
      date_to:'',
    }
  },
  mounted() {
    this.retrieveUsers();
    this.$store.dispatch('updateStats');
  },
  computed:{
    ...mapGetters({users:'getMembers','stats':'getStats'}),
      minDate () {
          return new Date(
              this.currentDate.getFullYear() - 1,
              this.currentDate.getMonth(),
              this.currentDate.getDate()
          );
      },
      maxDate () {
          return new Date(
              this.currentDate.getFullYear() + 1,
              this.currentDate.getMonth(),
              this.currentDate.getDate(),
          );
      },
  },
  watch: {
    users() {
        console.log('8***')
        console.log(this.totalRows)
      this.totalRows = this.users.meta.total;
    },
    searchTitle() {
      this.retrieveUsers();
    },
    date() {
        this.date_from  = this.date.start;
        this.date_to  = this.date.end;
         this.retrieveUsers();
    }
  },
  methods:{
      generateExcel() {
          const formData = {date_from:this.date.start,date_to:this.date.end,type:'active'}
          axios.post('members/export-report',formData)
              .then((res) =>{
               window.location.href = res.data;
              })
      },
    retrieveUsers() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage,
          this.date_from,
          this.date_to,
      );
      this.$store.dispatch('updateMembers',params)
    },
    handlePageChange(value) {
      this.currentPage = value
      this.retrieveUsers();
    }
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.5) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
