import AuthService from './../../services/AuthService';
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null,current_user:null,login_id:null,is_agent:false};
console.log(initialState)
export const auth = {
    namespaced: true,
    state:initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                response => {
                    commit('updateLoginId', response.data);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        loginPhone({ commit }, user) {
            return AuthService.loginPhone(user).then(
                response => {
                    commit('updateLoginId', response.data);
                    commit('updateScope');
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        otp({ commit }, user) {
            return AuthService.otp(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                   commit('loginFailure');
                   return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        loginSuccess({commit},data) {
            commit('loginSuccess',data);
        },
        sendEmail({commit}, email){
            return AuthService.sendEmail(email).then(
                response => {
                  commit('currentUser',response.data)
                   return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        reset({ commit }, user) {
            return AuthService.reset(user).then(
                response => {
                    console.log('success')
                    return Promise.resolve(response.data);
                },
                error => {
                    console.log('error')
                    console.log(error)
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        loginSuccess(state,data) {
            state.status.loggedIn = true;
            state.login_id = data;
            state.user = 'cool';
        },
        loginFailure(state) {
            console.log(state)
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        updateLoginId(state,data) {
            state.login_id = data.login_id;
        },
        updateScope(state) {
            state.is_agent = true;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        currentUser(state,user) {
            state.current_user = user;
        }
    },
    getters:{
        getLoginId(state) {
            return state.login_id;
        },
        getScope(state) {
            return state.is_agent;
        }
    }
};
