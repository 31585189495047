export const loan_fields = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'loan_reference_no',
        sortable: true,
    },
    {
        key: 'customer_name',
        sortable: true,
    },
    {
        key: 'phone',
        sortable: true,
    },
    {
        key: 'principal',
        sortable: true
    },
    {
        key: 'interest',
        sortable: true,
    },
    {
        key: 'payable',
        sortable: true,
    },
    {
        key: 'already_paid',
        sortable: true,
    },
    {
        key: 'balance',
        sortable: true,
    },
    {
        key: 'deadline',
        label: 'Date Created',
        sortable: true,
    },
    {
        key: 'date_created',
        sortable: true,
    },
    {
        key: 'actions',
        label: ''
    }
];
