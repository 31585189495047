export const referral_fields = [
    {
        key: 'MemberId',
        label: '#',
        sortable: true
    },
    {
        key: 'referral_phone',
        label: 'Phone',
        sortable: true
    },
    {
        key: 'referee_name',
        label: 'Referee name',
        sortable: true
    },
    {
        key: 'referee_phone',
        label: 'Referee Phone',
        sortable: true
    },

    {
        key: 'created_at',
        label: 'Date Referred',
        sortable: true,
    },
    {
        key: 'date_joined',
        label: 'Date Joined',
        sortable: true,
    },
    {
        key: 'referral_code',
        label: 'Referral Code',
        sortable: true,
    },
    {
        key: 'invite_name',
        label: 'Referred Name',
        sortable: true,
    },
    {
        key: 'member_status',
        label: 'Status',
        sortable: true,
    },

];
