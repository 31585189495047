import axios from 'axios';
class AuthService {
    login(user) {
        console.log(user)
        return axios
            .post('login', {
               email: user.email,
               password: user.password
            }
            )
            .then(response => {
                localStorage.setItem('is_agent', false);
                return response.data;
            });
    }
    loginPhone(user) {
        return axios
            .post('login/phone', {
                    phone_number: user.phone_number
                }
            )
            .then(response => {
                localStorage.setItem('is_agent', true);
                localStorage.setItem('login_id', response.data.data.login_id);
                return response.data;
            });
    }
    otp(payload) {
        console.log(payload)
        return axios
            .post('authenticate', payload)
            .then(response => {
                if (response.data.data.token) {
                localStorage.setItem('flex_token', response.data.data.token);
                localStorage.setItem('login_id', response.data.data.login_id);
                }
                return response.data.data;
            });
    }
    logout() {
        localStorage.removeItem('flex_token');
        localStorage.removeItem('is_agent');
        localStorage.removeItem('login_id');
    }

    sendEmail(email) {
       return axios.post('password/email',{email})
    }

    reset(user) {
        return axios.post(`password/reset/token=${user.token}`,user)
    }
}
export default new AuthService();
