export const withdrawal_matured_field_defs = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'customer_name',
        sortable: true,
    },
    {
        key: 'phone_number',
        sortable: true,
    },
    {
        key: 'amount',
        sortable: true,
    },
    {
        key: 'date_created',
        sortable: true,
    }
];
