<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">

        <div class="col-xl-4 xl-100 box-col-12">
          <px-card class="current-bal">
            <div slot="with-padding" class="crypto-current">
              <div class="row">
                <div class="col-xl-10 col-sm-10">
                  <div class="current-balance"><span class="f-12 f-w-600">Total Withdrawn</span>
                    <h2><span class="mr-2"><i class="icofont icofont-cur-dollar"></i></span><span>6,00,566</span></h2>
                    <div class="btn-vertical-align d-flex">
                      <div class="setting-dot d-inline-block">
                        <div class="setting-bg"><i class="fa fa-spin fa-cog font-primary"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-sm-2">
                  <div class="text-right">
                    <div class="user-circle"><i class="icofont icofont-cur-dollar"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>

        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Payouts</h5>
                </div>
                <div class="col-md-6">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-4">
                  <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                  >
                    <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="payout.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="payout.data && payout.data.length >= 1"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="handlePageChange"
                    class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {payout_field_defs} from "@/pages/payments/fields/payout_field_defs";
export default {
  data() {
    return {
      fields: payout_field_defs,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      searchTitle: ""
    }
  },
  mounted() {
    this.retrievePayout();
  },
  computed:{
    ...mapGetters({payout:'getPayout'})
  },
  watch: {
    payout() {
      this.totalRows = this.payout.total;
    },
    searchTitle() {
      this.retrievePayout();
    }
  },
  methods:{
    retrievePayout() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
      this.$store.dispatch('updatePayout',params)
    },
    handlePageChange(value) {
      this.currentPage = value
      this.retrievePayout();
    }
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}
</style>
