<template>

</template>
<script>
import { mapState } from "vuex";
export default {
	name: 'customizer',
	data(){
		return {
      customizer: "",
      sidebarSetting: "color",
      layoutType: "ltr",
      sidebarType: "default",
      mixLayout: "default",
      styleObject: {
        position: "fixed",
        left: "0",
        top: "0",
        opacity: "0"
      }
    };
	},
	mounted() {
    this.layout.color.color = localStorage.getItem("color");
    this.layout.color.primary_color = localStorage.getItem("primary_color");
    this.layout.color.secondary_color = localStorage.getItem("secondary_color");
  },
  computed: {
    ...mapState({
      layout: state => state.layout.layout,
      data: state => JSON.stringify(state.layout.layout)
    })
  },
	methods: {
    openCustomizerSetting(val) {
      this.customizer = val;
    },
    customizerSetting(val) {
      this.sidebarSetting = val;
    },
    customizeLayoutType(val) {
      this.$store.dispatch("layout/setLayoutType", val);
      this.layoutType = val;
    },
    customizeSidebarType(val) {
      this.$store.dispatch("layout/setCustomizeSidebarType", val);
    },
    customizeSidebarSetting(val) {
      this.layout.settings.sidebar_setting = val;
    },
    customizeSidebarBackround(val) {
      this.layout.settings.sidebar_backround = val;
    },
    customizeMixLayout(val) {
      this.mixLayout = val;
      this.$store.dispatch("layout/setLayout", val);
    },
    customizeLightColorScheme(val) {
      this.lightColor = val;
      this.$store.dispatch("layout/setColorLightScheme", val);
    },
    customizeDarkColorScheme(val) {
      this.$store.dispatch("layout/setColorDarkScheme", val);
	},
	customizeTransparentBackground(val) {
	  this.$store.dispatch("layout/setbgTransparent", val);
	},
    copyText() {
      this.$refs.layout.select();
      document.execCommand("copy");
      this.$toasted.show("Code Copied to clipboard", {
        theme: "outline",
        position: "top-right",
        type: "default",
        duration: 2000
      });
    }
  }
}
</script>
