<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">

<div class="row">

  <div class="col-xl-4 xl-50 col-6 hospital-patients">
    <px-card >
      <div slot="with-padding" class="hospital-widgets media">
        <div class="hospital-box light-bg-danger"></div>
        <div class="media-body">
          <div class="hospital-content">
            <h3 class="d-inline-block f-w-600">{{customer_stats.registered}}</h3><span class="badge flat-badge-secondary m-l-10"></span>
            <h6 class="m-t-5 mb-0 f-w-600"> Registered Customers</h6>
          </div>
          <div class="flowers">
            <div class="flower1"></div>
            <div class="flower2"></div>
            <div class="flower3"></div>
          </div>
        </div>
      </div>
    </px-card>
  </div>

    <div class="col-xl-4 xl-50 col-6 hospital-patients">
    <px-card>
      <div slot="with-padding" class="hospital-widgets media">
        <div class="hospital-box light-bg-primary"></div>
        <div class="media-body">
          <div class="hospital-content">
            <h3 class="d-inline-block f-w-600">{{customer_stats.registered}}</h3><span class="badge flat-badge-primary m-l-10"></span>
            <h6 class="m-t-5 mb-0 f-w-600">Active Customers</h6>
          </div>
          <div class="flowers">
            <div class="flower1 flower-primary"></div>
            <div class="flower2 flower-primary"></div>
            <div class="flower3 flower-primary"></div>
          </div>
        </div>
      </div>
    </px-card>
    </div>

  <div class="col-xl-4 xl-50 col-6 hospital-patients">
    <px-card>
      <div slot="with-padding" class="hospital-widgets media">
        <div class="hospital-box light-bg-primary"></div>
        <div class="media-body">
          <button  class="btn btn-pill btn-gradient-custom" @click="createNew()">Register New<span class="round-shape1"><i class="icofont icofont-arrow-right"></i></span></button>
          <div class="flowers">
            <div class="flower1 flower-primary"></div>
            <div class="flower2 flower-primary"></div>
            <div class="flower3 flower-primary"></div>
          </div>
        </div>
      </div>
    </px-card>
  </div>
</div>
    </div>
    <!-- Container-fluid Ends -->
    <app-customer></app-customer>

  </div>
</template>
<script>
import * as Chartist from 'chartist';
import TotalSales from "@/pages/charts/chartjs/TotalSales";
import {mapGetters} from "vuex";
import AppCustomer from "@/pages/customers/AppCustomer.vue";
export default {
  components:{
    'Linechart':TotalSales,
    AppCustomer
  },
  data() {
    return {
    };
  },
  methods: {
    createNew() {
      bus.$emit('show_create_form')
    },
    openCardOption() {
      this.cardOption = !this.cardOption;
    }
  },

  created() {
    this.$store.dispatch('updateStats')
    this.$store.dispatch('updateCustomerStats',localStorage.getItem('login_id'))
  },
  watch:{
    stats() {
      console.log(this.stats)
    }
  },
  computed:{
    ...mapGetters({stats:'getStats',customer_stats:'getCustomerStats'})
  }
};
</script>

<style scoped>
.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.1) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
