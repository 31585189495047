import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
// Bootstrap Framework Component
import {BootstrapVue} from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import SmartTable from 'vuejs-smart-table'
import Toasted from 'vue-toasted';

// Vue Router
import router from './router'
import VueFormWizard from 'vue-form-wizard';

// Vue Router
import Breadcrumbs from './components/bread_crumbs'

// Vuex Store
import { store } from './store';
import VueFeather from 'vue-feather';

import PxCard  from './components/Pxcard.vue'
Vue.component(PxCard.name, PxCard)
Vue.use(Toasted,{
  iconPack: 'fontawesome'
});
import VueApexCharts from 'vue-apexcharts'
import Chartist from "chartist";

import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);
// Import Theme scss
import './assets/scss/app.scss'
import Spinner from "@/components/Spinner.vue";
Vue.use(require('vue-moment'));

// import VueDatePicker from '@mathieustan/vue-datepicker';
// import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

// Vue.use(VueDatePicker);

axios.defaults.baseURL="https://api.flexsako.com/api/v1/";
//axios.defaults.baseURL="http://localhost:8000/api/v1/";
axios.defaults.headers.common["Authorization"] = "Bearer 2|LtcLjaZ4O9ukX3YvsKnJuxxvAApC5IIvWbJlzzaJ6Fv5XwO0GLM8Q6Q4MbtWhHWZXVQDTiewELGCIpEDc82IhF0qLdZhCXC0L2GfdvWdcBpl6roS2F1yQgTmw1S3vSQW9TdzwHsEEjG1xCkZthjOhC1u4lN002jWXllLmYFEIrUVFSdfM7qUEku41pcIWUEA7fiv8LNMPcaTEyL2wdJPF4d3SFh3kQCvDP4yLA4Rc2fibOM5";
axios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.config.productionTip = false

Vue.use(VueFeather);
Vue.use(BootstrapVue);
//Vue.use(BootstrapVuePlugin);
Vue.use(VueSweetalert2);
Vue.use(SmartTable);
Vue.use(VueFormWizard);
Vue.use(require('vue-chartist'))
Vue.component('apexchart', VueApexCharts)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('app-spinner', Spinner)
Vue.prototype.$Chartist = Chartist;
window.bus = new Vue();

bus.$emit('ww', 'eventData');

// In another component, listen for the event
bus.$on('ww', eventData => {
  // Handle the event
  console.log('cool stuff')
  console.log(eventData)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
