export function getRequestParams(search, page, pageSize,date_from,date_to) {
    let params = {};
   // if (search) {
        params["search"] = search;
    //}
    if (page) {
        params["page"] = page;
    }
    if (pageSize) {
        params["size"] = pageSize;
    }
    if (date_from && date_from !==undefined) {
        console.log('undefined...')
        params["date_from"] = date_from;
    }else {
        params["date_from"] = "";
    }
    if (date_to && date_to !==undefined) {
        params["date_to"] = date_to;
    }else {
        params["date_to"] = "";
    }
    return params;
}
