import axios from "axios";

class WithdrawService {
    getWithdrawRequests(payload){
       var url = `withdrawal/requests?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}`
        return axios.get(url)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }


    getWithdrawApproved(payload){
        var url = `withdrawal/approved?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}`
        return axios.get(url)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }

    getWithdrawMatured(payload){
        var url = `withdrawal/matured?page=${payload.page}&page_size=${payload.size}&search=${payload.search}&date_from=${payload.date_from}&date_to=${payload.date_to}`
        return axios.get(url)
            .then(res => {
                return res.data;
            }).catch(e=> {
                console.log(e)
            })
    }
}



export default new WithdrawService();
