export const withdrawal_requests_field_defs = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'customer_name',
        sortable: true,
    },
    {
        key: 'phone_number',
        sortable: true,
    },
    {
        key: 'amount',
        sortable: true,
    },
    {
        key: 'interest_earned',
        sortable: true,
    },
    {
        key: 'status',
        sortable: true,
    },
    {
        key: 'date_created',
        sortable: true,
    },
    {
        key: 'actions',
        label: ''
    }
];
