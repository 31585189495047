export const payout_field_defs = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'transaction_code',
        label: 'Reference',
        sortable: true
    },
    {
        key: 'date_created',
        label: 'Date',
        sortable: true,
    },
    {
        key: 'amount',
        sortable: true,
    },
    {
        key: 'mode',
        sortable: true,
    },
    {
        key: 'account',
        sortable: true,
    }
];
