<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-md-4">
                <div class="card card-stats mb-4 mb-xl-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Membership fee</h6>
                                <span class="h4 font-weight-bold">{{stats.membership_fee}}</span>
                            </div>
                            <div class="col-auto">

                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-muted text-sm">
                            <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i> 3.48%</span>
                            <span class="text-nowrap">Compared to last month</span>
                        </p>
                        <hr>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card card-stats mb-4 mb-xl-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Subscribed Members</h6>
                                <span class="h4 font-weight-bold">{{stats.active_members}}</span>
                            </div>
                            <div class="col-auto">
                                <div class="">
                                    <i class="fa fa-users fa-3x"></i>
                                </div>
                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-muted text-sm">
                            <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i> 3.48%</span>
                            <span class="text-nowrap">Compared to last month</span>
                        </p>
                        <hr>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card card-stats mb-4 mb-xl-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h6 class="card-title text-uppercase text-muted" style="font-size: 12px">Total Members</h6>
                                <span class="h4 font-weight-bold">{{stats.all_members}}</span>
                            </div>
                            <div class="col-auto">
                                <div class="">
                                    <i class="fa fa-users fa-3x"></i>
                                </div>
                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-muted text-sm">
                            <span class="card_green  mr-2"><i class="icon-arrow-top-right"></i> 3.48%</span>
                            <span class="text-nowrap">Compared to last month</span>
                        </p>
                        <hr>
                    </div>
                </div>
            </div>
        </div>

      <div class="row">
        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Members List</h5>
                </div>
                <div class="col-md-4">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <VueDatePicker
                                        v-model="date"
                                        :min-date="minDate"
                                        :max-date="maxDate"
                                        placeholder="Select Date Range"
                                        class="form-control"
                                        range
                                        fullscreen-mobile
                                        validate
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-primary" @click="generateExcel()" :disabled="!date">Export Excel</button>
                        </div>
                    </div>
                </div>

              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="users.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="users.data && users.data.length >= 1"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              @change="handlePageChange"
                              class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {inactive_member_fields} from "@/pages/members/inactive/fields/member_field_defs";
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import axios from "axios";

export default {
    components:{
        VueDatePicker
    },
  data() {
    return {
      fields: inactive_member_fields,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 150, 200],
      searchTitle: "",
      date: "",
      currentDate: new Date(),
      date_from:'',
      date_to:'',
    }
  },
  mounted() {
    this.retrieveUsers();
    this.$store.dispatch('updateStats');
  },
  computed:{
    ...mapGetters({users:'getPartialMembers','stats':'getStats'}),
      minDate () {
          return new Date(
              this.currentDate.getFullYear() - 1,
              this.currentDate.getMonth(),
              this.currentDate.getDate()
          );
      },
      maxDate () {
          return new Date(
              this.currentDate.getFullYear() + 1,
              this.currentDate.getMonth(),
              this.currentDate.getDate(),
          );
      },
  },
  watch: {
    users() {
      this.totalRows = this.users.meta.total;
    },
    searchTitle() {
      this.retrieveUsers();
    },
      date() {
          this.date_from  = this.date.start;
          this.date_to  = this.date.end;
          this.retrieveUsers();
      }
  },
  methods:{
    generateExcel() {
        const formData = {date_from:this.date.start,date_to:this.date.end,type:'partial'}
        axios.post('members/export-report',formData)
            .then((res) =>{
                window.location.href = res.data;
            })
    },
    retrieveUsers() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage,
          this.date_from,
          this.date_to,
      );
      this.$store.dispatch('updatePartialMembers',params)
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveUsers();
    }
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.5) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
