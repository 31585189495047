<template>
<div>
  <div class="fxt-content">
    <div class="fxt-header">
      <a href="/" class="fxt-logo"><img src="../../assets/images/logo.png" alt="Logo" width="45%"></a>

      <div class="fxt-style-line">
        <h2>Verify Otp To Proceed</h2>
      </div>
    </div>
    <div v-if="message" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
      <p class="text-danger">
        {{ message }}
      </p>
    </div>
    <div class="fxt-form">
      <form method="POST" @submit.prevent="verifyOtp">
        <div class="form-group">
          <div class="fxt-transformY-50 fxt-transition-delay-1">
            <input type="number" class="form-control" v-model="otp" placeholder="Enter Otp" required>
          </div>
        </div>
        <div class="form-group">
          <div class="fxt-transformY-50 fxt-transition-delay-4">
            <button type="submit" class="fxt-btn-fill" v-if="!loading">Verify</button>
            <app-spinner v-if="loading"></app-spinner>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import User from '../../models/user';
import {mapGetters} from "vuex";
export default {
  name: "login",
  data() {
    return {
      loading: false,
      message: '',
        otp: ""
    };
  },
  computed: {
   ...mapGetters({login_id:'auth/getLoginId',scope_type:'auth/getScope'})
  },
  methods: {
    verifyOtp(){
      this.loading = true;
        if (this.login_id && this.otp) {
          this.$store.dispatch('auth/otp', {otp:this.otp,login_id:this.login_id}).then(
              () => {
               this.loading = false;
               if (this.scope_type===true){
                 this.$router.push('/');
               }else {
                 this.$router.push('/');
               }

                this.hideForm();
              },
              error => {
                this.$toasted.show("Invalid Otp. Please try again.", {theme: 'outline',position: "top-center", type: 'error', duration: 2000});
                this.message = error.response.data.error;
                this.loading = false;
                this.hideForm();
              }
          );
        }
    },
    hideForm() {
      bus.$emit('hide_otp');
    }

  },
};
</script>

<style scoped>
</style>
