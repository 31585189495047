import savings from "@/services/Saving";


const state = {
    savings:{},
    savings_summary:{},
}

const mutations = {
    updateSavings(state,payload) {
        state.savings = payload;
    },
    updateSavingsSummary(state,payload) {
        state.savings_summary = payload;
    }
}

const getters = {
    getSavings(state) {
        return state.savings;
    },
    getSavingsSummary(state) {
        return state.savings_summary;
    }
}

const actions = {
    updateSavings({commit}, payload) {
        return savings.getSavings(payload).then(response => {
                commit('updateSavings',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    },
    updateSavingsSummary({commit}, payload) {
        return savings.getSavingsSummary(payload).then(response => {
                commit('updateSavingsSummary',response)
                return Promise.resolve(response);
            },
            err => {
                return Promise.reject(err);
            }
        )
    }
}

export default {
    namespace: true,
    state,
    mutations,
    actions,
    getters
}
