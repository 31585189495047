<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Approved Loans</h5>
                </div>
                <div class="col-md-4">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <VueDatePicker
                            v-model="date"
                            :min-date="minDate"
                            :max-date="maxDate"
                            placeholder="Select Date Range"
                            class="form-control"
                            range
                            fullscreen-mobile
                            validate
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <button class="btn btn-primary" @click="generateExcel()" :disabled="!date">Export Excel</button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="approvals.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="approvals.data && approvals.data.length >= 1"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              @change="handlePageChange"
                              class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {VueDatePicker} from "@mathieustan/vue-datepicker";
import axios from "axios";
import {approved_withdrawals_field_defs} from "@/pages/withdrawals/fields/approved_withdrawals_field_defs";
export default {
  components: {VueDatePicker},
  data() {
    return {
      fields: approved_withdrawals_field_defs,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 50, 100],
      searchTitle: "",
      date: "",
      currentDate: new Date(),
      date_from:'',
      date_to:'',
    }
  },
  mounted() {
    this.retrieveApprovals();
  },
  computed:{
    ...mapGetters({approvals:'getApprovals'}),
    minDate () {
      return new Date(
          this.currentDate.getFullYear() - 1,
          this.currentDate.getMonth(),
          this.currentDate.getDate()
      );
    },
    maxDate () {
      return new Date(
          this.currentDate.getFullYear() + 1,
          this.currentDate.getMonth(),
          this.currentDate.getDate(),
      );
    },
  },
  watch: {
    approvals() {
      this.totalRows = this.approvals.meta.total;
      console.log('rows '+this.totalRows)
      console.log(this.loans.data.length)
    },
    searchTitle() {
      this.retrieveApprovals();
    },
    date() {
      this.date_from  = this.date.start;
      this.date_to  = this.date.end;
      this.retrieveApprovals();
    }
  },
  methods:{
    retrieveApprovals() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
      this.$store.dispatch('updateApprovals',params)
    },
    handlePageChange(value) {
      this.currentPage = value
      this.retrieveApprovals();
    },
    generateExcel() {
      const formData = {date_from:this.date.start,date_to:this.date.end}
      axios.post('withdrawal/approved-export',formData)
          .then((res) =>{
          })
    },
  }
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.5) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
