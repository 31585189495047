import payments from "@/services/Payments";

const state = {
    available_amount:[],
    payout:[],
    total_items:0
}

const getters = {
    getAvailableAmount: (state) => {
        return state.available_amount;
    },
    getPayout: (state) => {
        return state.payout;
    },
}

const mutations = {
    updateAvailableAmount: (state,payload) =>{
        state.available_amount = payload;
        state.total_items = payload.total
    },
    updatePayout: (state,payload) =>{
        state.payout = payload;
        state.total_items = payload.total
    }
}

const actions = {
    updateAvailableAmount({commit},payload) {
        return payments.availableAmount(payload).then(
            response => {
                commit('updateAvailableAmount',response);
               return Promise.resolve(response)
            },
            error => {
             return Promise.reject(error);
            }
        )
    },
    updatePayout({commit},payload) {
        return payments.payout(payload).then(
            response => {
                commit('updatePayout',response);
                return Promise.resolve(response)
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
}


export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions
}

